<template>
  <router-view />
</template>

<script>
export default {
  mounted () {
    let loader = this.$loading.show();
    setTimeout(() => loader.hide(), Math.random() * 200 + 200)
  },
}
</script>
